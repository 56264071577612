<template>
<div class="box box-primary records">
    <div class="box-body" v-if="this.videoUrl">
        
        <LivePlayer 
        :currentTime="currentTime"
        :videoUrl="videoUrl" 
        muted
        element-loading-text="加载中" 
        element-loading-background="#000"
        style="margin:0 auto; max-width:700px;" 
        @ended="onVideoEnd" 
        />
        <!-- <LivePlayer 
        :videoUrl="videoUrl" 
        muted 
        :hideBigPlayButton="!!serverInfo.HideBigPlayButton" 
        :currentTime="currentTime" 
        @ended="onVideoEnd" 
        @timeupdate="onVideoTimeUpdate" 
        style="margin:0 auto; max-width:700px;" 
        :loading.sync="loading" 
        v-loading="loading" 
        element-loading-text="加载中" 
        element-loading-background="#000">
        </LivePlayer> -->
    </div>
</div>
</template>

<script>
import moment from 'moment'
import LivePlayer from '@liveqing/liveplayer'
import axios from 'axios'
import API from "@/api";

import {
    mapState
} from "vuex";

export default {
    props: {
        serial: '',
        code: '',
        day: '',
        datalist: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            videos: [],
            videoUrl: "",
            currentTime: 0,
            bActive: false,
            loading: false,
            loadingRecords: false,
            name: '',
            index: 0
        }
    },
    components: {
        LivePlayer,
    },
    methods: {
        updateDay(day) {
            this.$router.replace(`/cloudrecord/timeview/${this.serial}/${this.code}/${day}`);
        },
        showCloudRecordDatePicker() {
            $(this.$refs['datePicker'].$el).focus();
        },
        updateVideos() {
            this.loadingRecords = true;
            $.get("/api/v1/cloudrecord/querydaily", {
                serial: this.serial,
                code: this.code,
                period: this.day
            }).then(data => {
                this.name = data.name || `${this.serial}:${this.code}`;
                this.videos = data.list;
            }).always(() => {
                this.loadingRecords = false;
            })
        },
        onTimeChange(video) {
            this.videoUrl = (video || {}).hls || "";
            this.currentTime = (video || {}).currentTime || 0;
        },
        onVideoEnd() {
            // var idx = this.videoUrls.indexOf(this.videoUrl);
            // if (idx >= 0 && idx < this.videos.length - 1) {
            //     var nextVideo = this.videos[idx + 1];
            //     if (!nextVideo) return;
            //     var startTime = moment(nextVideo.startAt, 'YYYYMMDDHHmmss');
            //     var n = startTime.diff(startTime.clone().startOf('day'), 'minutes');
            //     this.$refs['timeRule'].clickMinute(n);
            // }
            if(this.index < this.videos.length -1) {
                this.index ++
            }
        },
        onVideoTimeUpdate(currentTime) {
            var idx = this.videoUrls.indexOf(this.videoUrl);
            if (idx >= 0 && idx < this.videos.length) {
                var video = this.videos[idx];
                var startTime = moment(video.startAt, 'YYYYMMDDHHmmss');
                startTime.add(currentTime, 'seconds');
                var n = startTime.diff(startTime.clone().startOf('day'), 'minutes');
                this.$refs['timeRule'].clickMinute(n, false);
            }
        },
        async aa(data) {
            let promise = await axios.get('http://39.99.144.107:10800/api/v1/record/querydaily', {
            params: {
                ...data
            }
        })
        return promise
        },
    },
    computed: {
        ...mapState(['userInfo', 'serverInfo']),
        videoUrls() {
            return this.videos.map((val, index, array) => {
                return (val || {}).hls;
            });
        }
    },
    async mounted() {
        // let aa =  this.aa()
        // console.log('aa', aa);
        // if(aa.status === 200){
        //     if(aa.data.list.length > 0) {
        //     this.videos = aa.data.list
        //     this.videoUrl = 'http://172.16.6.16:10800' + aa.data.list[0].hls
        //     } else {
        //         this.$message.error('暂无视频')
        //     }
        // } else {
        //     this.$message.error('暂无数据')
        // }  

        // /api/v1/record/querydaily?id=3&period=20220730&start=202207300935&end=202207301015
        console.log('datalist', this.datalist);
        
        let url = this.datalist.key
        let data = {}
        url.split('?')[1].split('&').map(item => item.split('=')).map(item => data[item[0]] = item[1])
        let m3u8data = await this.aa(data)
        if(m3u8data.status === 200) {
            console.log('111', m3u8data.data.list);
            if(m3u8data.data.list.length > 0) {
                let list = m3u8data.data.list.filter(item =>  item.startAt > data.start)
                console.log('list', list);
            this.videos = m3u8data.data.list
            this.videoUrl = 'http://39.99.144.107:10800' + m3u8data.data.list[0].hls
            } else {
                this.$message.error('暂无视频')
            }
        } else {
            this.$message.error('暂无数据')
        }
        
    },
    watch: {
        index: function(newVal, oldVal) {
            this.videoUrl = 'http://39.99.144.107:10800' + this.videos[this.index].hls
        }
    }
}
</script>
