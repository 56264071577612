<template>
  <div class="cpt-task-achievement-search">
    <div style="display: flex; align-items: center">
      <el-form
        :model="search_form"
        style="margin-left: 24px"
        class="linear-form"
        inline
      >
        <el-form-item label="任务名称:">
          <el-input
            v-model="search_form.name"
            size="mini"
            placeholder="请输入任务名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="任务类型:">
          <el-select v-model="search_form.taskCateId" size="mini" clearable placeholder="请选择任务类型">
            <el-option
              v-for="item in drd.type"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="场景类型:">
          <el-select v-model="search_form.taskTypeId" size="mini" clearable placeholder="请选择场景类型">
            <el-option
              v-for="item in drd.scenes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <div class="searbutton">
            <div @click="$emit('on-searchTask', search_form)" class="linear-btn">搜索</div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import API from '@/api'
export default {
    props:{
      id: {
      type: Number | String,
      default: null,
    },
    },
  data() {
    return {
      search_form: {
        name: null,
        taskCateId: null,
        taskTypeId:null,
        taskStatus:1,
      },
      drd: {
        type: [
          { id: 2, title: "常态任务" },
          { id: 3, title: "临时紧急任务" },
        ],
        scenes:null
      },
    };
  },
 async mounted(){
      let res = await API.TASK.GetDrdCate();
      res = res.data;
      this.drd.scenes = res 
  }
};
</script>

<style lang="scss" scoped>
.cpt-task-achievement-search {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 5px;
  .searbutton {
    display: flex;
    .rigth {
      margin-left: 30px;
    }
  }
}
::v-deep .el-input{
  width: 178px;
  .el-input__inner{
    width: 100% !important;
  }
}
</style>