<template>
    <div class="videoJs h200" v-loading="loading"
    element-loading-text="视频加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
      <video
       v-if="url"
        ref="videoPlayer"
        class="video-js vjs-default-skin vjs-big-play-centered"
        autoplay="autoplay"
      >
      <source :src="url" :type="type">
      </video>
    </div>
</template>

<script>
import axios from 'axios'
import "videojs-markers";
import dayjs from "dayjs";
export default {
  props: {
    datalist: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        autoplay: "muted", //自动播放
        controls: true, //用户可以与之交互的控件
        loop: false, //视频一结束就重新开始
        muted: true, //默认情况下将使所有音频静音
        playsinline: true,
        webkitPlaysinline: true,
        // aspectRatio:"16:9",//显示比率
        playbackRates: [0.5, 1, 1.5, 2],
        language: "zh-CN",
        fullscreen: {
          options: { navigationUI: "hide" },
        },
 
      },
      player: null,
      markers: [],
      loading: false,
      index: 0,// 视频下标
      videoList: [],// 视频数组
      url: null,// 视频地址
      type: null,// 视频格式
      VUE_APP_M3U8: null,
      VUE_APP_VIDEOIP: null
    };
  },
  methods: {
    init() {
      // 存储this对象
      let that = this
      // this.loading = true
      this.player = this.$video(this.$refs.videoPlayer, this.options);
      // 设置标点
      this.player.markers({
        // 不显示鼠标悬浮标记提示R文字
        markerTip: {
          display: false,
        },
        markerStyle: {
          width: "7px",
          "background-color": "red",
          "border-radius": "50%",
        },
        markers: this.markers,
      });
    this.player.on('loadedmetadata', () => {//成功获取资源长度
        console.log("成功获取资源长度")
        this.loading = false
    });
    this.player.on('loadeddata', function() {//渲染播放画面
        // console.log("渲染播放画面")
    });
    this.player.on('waiting', function() {//等待数据，并非错误
        // console.log("等待数据")
    });
    this.player.on('playing', function() {//开始回放
        // console.log("开始回放")
    });
    this.player.on('canplay', function() {//可以播放，但中途可能因为加载而暂停
        console.log("可以播放，但中途可能因为加载而暂停")
        that.loading =false
    });
    this.player.on('canplaythrough', function() { //可以播放，歌曲全部加载完毕
        console.log("可以播放，歌曲全部加载完毕")
    });
    this.player.on('seeking', function() { //寻找中
        // console.log("寻找中")
    });
    this.player.on('seeked', function() {//寻找完毕
        // console.log("寻找完毕")
    });
    this.player.on('timeupdate', function() {//播放时间改变
        console.log("播放时间改变")
    });
    this.player.on('ended', function() {//播放结束
        console.log("播放结束", that.videoList)
        if(that.index < that.videoList.length -1) {
          // that.options.sources[0].src = null
          // that.index = that.index + 1
          // setTimeout(() => {
          //   that.player.play()
          // })
          this.url = null
          that.index = that.index + 1
          setTimeout(() => {
            that.player.play()
          })
          
        } else {
          that.index = 0
        }
    });

    },
    // 解析传入时间，设置标记点
    async getData() {
      this.loading = true
      let { url, videoStartEnd, videoSign } = this.datalist;
      if (videoStartEnd && videoSign ) {
        let videoSignList = videoSign.split(";");
        let startTime = JSON.parse(videoStartEnd)[0].startTime;
        videoSignList.map((item) =>
          this.markers.push({

            time: dayjs(Number(item)).diff(Number(startTime)),
          })
        );
      }
      this.url = url
      
    },
    // 根据传入的url解析时间传参获取meu8视频列表
    async getList() {
      let url = this.datalist.url
      let data = {}
      url.split('?')[1].split('&').map(item => item.split('=')).map(item => data[item[0]] = item[1])
      let m3u8data = await this.getVideo(data)
      if(m3u8data.status === 200) {
          if(m3u8data.data.list.length > 0) {
            let list = m3u8data.data.list.filter(item =>  item.startAt > data.start)
            this.videoList = list
            this.videoList.map(item => item.hls = this.VUE_APP_M3U8 + item.hls)
            this.url = this.videoList[this.index].hls
          } else {
              this.$message.error('暂无视频')
          }
      } else {
          this.$message.error('暂无数据')
      }
    },
    // 获取m3u8视频
    async getVideo(data) {
      let promise = await axios.get(this.datalist.url)
      return promise
    },
    async mp4() {
      await this.getData();
      this.init()
    },
    async meu8() {
      await this.getList();
      this.init()
    },
    back() {
      if(this.index < 1) {

      } else {
        this.index --
      }
    },
    last() {
      if(this.index < this.videoList.length -1) {
        this.index ++
      }
    },
    updata() {
    }
  },
  mounted() {
    const { VUE_APP_M3U8, VUE_APP_VIDEOIP } = process.env;
    this.VUE_APP_M3U8 = VUE_APP_M3U8
    this.VUE_APP_VIDEOIP = VUE_APP_VIDEOIP
    this.loading = true
    // 根据url是否是接口形式  /api
    if(this.datalist.url.indexOf('/api') > -1) {
      this.type = 'application/x-mpegURL'
      this.meu8()
    } else {
      this.type = 'video/mp4'
      this.mp4()
    }
    
    
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch: {
    index: function(newVal, oldVal) {
      this.updata()
    }
  }
};
</script>
<style lang="scss" scoped>
.videoJs {
  width: 100%;
  height: 100%;
  .video-js{
    width: 100%;
    height: 100%;
  }
}
</style>
